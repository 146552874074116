export const NSIRS_FIND = 'NSIRS_FIND';
export const NSIRS_FIND_SUCCESS = 'NSIRS_FIND_SUCCESS';

export const NSIRS_IMPORT = 'NSIRS_IMPORT';
export const NSIRS_IMPORT_SUCCESS = 'NSIRS_IMPORT_SUCCESS';

export const NSIRS_EXPORT = 'NSIRS_EXPORT';
export const NSIRS_EXPORT_TO_EDIT = 'NSIRS_EXPORT_TO_EDIT';
export const NSIRS_EXPORT_FINANCEIRO = 'NSIRS_EXPORT_FINANCEIRO';

export const NSIRS_FIND_BY_ID = 'NSIRS_FIND_BY_ID';
export const NSIRS_FIND_BY_ID_SUCCESS = 'NSIRS_FIND_BY_ID_SUCCESS';

export const NSIRS_DELETE = 'NSIRS_DELETE';
export const NSIRS_DELETE_SUCCESS = 'NSIRS_DELETE_SUCCESS';

export const NSIRS_SAVE = 'NSIRS_SAVE';
export const NSIRS_SAVE_SUCCESS = 'NSIRS_SAVE_SUCCESS';

export const NSIRS_UPDATE_STATUS = 'NSIRS_UPDATE_STATUS';
export const NSIRS_UPDATE_STATUS_SUCCESS = 'NSIRS_UPDATE_STATUS_SUCCESS';

export const NSIRS_UPDATE = 'NSIRS_UPDATE';
export const NSIRS_UPDATE_SUCCESS = 'NSIRS_UPDATE_SUCCESS';

export const NSIRS_CLEAN_SELECTED = 'NSIRS_CLEAN_SELECTED';

export const NSIRS_CLEAN = 'NSIRS_CLEAN';

export const NSIRS_GENERATE_REPORT = 'NSIRS_GENERATE_REPORT';
export const NSIRS_GENERATE_REPORT_BY_ID = 'NSIRS_GENERATE_REPORT_BY_ID';
export const NSIRS_GENERATE_INSPECAO_REPORT = 'NSIRS_GENERATE_INSPECAO_REPORT';

export const NSIRS_INICIAR_DIGITACAO = 'NSIRS_INICIAR_DIGITACAO';
export const NSIRS_INICIAR_DIGITACAO_SUCCESS = 'NSIRS_INICIAR_DIGITACAO_SUCCESS';

export const NSIRS_TROCAR_DIGITADOR = 'NSIRS_TROCAR_DIGITADOR';
export const NSIRS_TROCAR_DIGITADOR_SUCCESS = 'NSIRS_TROCAR_DIGITADOR_SUCCESS';

export const NSIRS_IMPORT_SERVICES = 'NSIRS_IMPORT_SERVICES';
export const NSIRS_IMPORT_SERVICES_SUCCESS = 'NSIRS_IMPORT_SERVICES_SUCCESS';

export const NSIRS_UPDATE_FINANCEIRO = 'NSIRS_UPDATE_FINANCEIRO';
export const NSIRS_UPDATE_FINANCEIRO_SUCCESS = 'NSIRS_UPDATE_FINANCEIRO_SUCCESS';

export const NSIRS_IMPORT_FINANCEIRO = 'NSIRS_IMPORT_FINANCEIRO';
export const NSIRS_IMPORT_FINANCEIRO_SUCCESS = 'NSIRS_IMPORT_FINANCEIRO_SUCCESS';

export const NSIRS_EXPORT_CCSM = 'NSIRS_EXPORT_CCSM';

export const NSIRS_BASE_EXPORT = 'NSIRS_BASE_EXPORT';
export const NSIRS_BASE_IMPORT = 'NSIRS_BASE_IMPORT';

export const NSIRS_REPORT_USO_MUTUO = 'NSIRS_REPORT_USO_MUTUO';