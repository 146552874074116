/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { Grid, DialogContentText } from '@material-ui/core';
import moment from 'moment';
import UsuarioModal from './UsuarioModal';
import StatusModal from './StatusModal';
import {
  Input,
  InputFormat,
  Widget,
  MalhaSelect,
  GerenciaSelect,
  PoloSelect,
  SubestacaoSelect,
  AlimentadorSelect,
  DatePicker,
  NsirStatusSelect,
  DefaultSelect,
  SimNaoSelect,
  UsuarioSelect,
  NsirSyncStatusSelect,
  Dropzone,
  DialogDefault,
  TrueFalseSelect,
  OptionSelect
} from '../../components';
import * as Roles from '../../constants/role';
import * as Status from '../../constants/status';
import * as actionsNsirs from '../../store/nsir/actions';
import * as selectorsAuth from '../../store/auth/reducer';
import { isUsuarioUsoMuto } from 'utils';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    padding: theme.spacing(2),
    paddingTop: 20,
    paddingBottom: 30
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  }
}));

const InformacaoBasicaTab = ({
  nsir,
  onSave,
  authData,
  generateNsirReportById,
  updateStatus,
  iniciarDigitacaoNsir,
  trocarDigitadorNsir,
  exportCcsm
}) => {
  const classes = useStyles();

  const [statusModal, setStatusModal] = useState(false);
  const [openCcsm, setOpenCcsm] = useState(false);
  const [inspetorModal, setInspetorModal] = useState({ open: false, isChange: false });
  const [digitadorModal, setDigitadorModal] = useState({ open: false, isChange: false });
  const [digitacaoFinalizadaError, setDigitacaoFinalizadaError] = useState({ open: false, message: '' });
  const [errors, setErrors] = useState({});
  const [documentoKmz, setDocumentoKmz] = useState(null);
  const [values, setValues] = useState({
    vencimento: moment().valueOf(),
    status: 'EM_CRIACAO',
    inspecaoTermografica: false,
  });

  useEffect(() => {
    if (nsir && nsir.id) {
      setValues({
        ...nsir,
        vencimento: moment(nsir.vencimento)
          .locale('ptBR')
          .toISOString()
      });
      if (nsir.documentoKmzFile) {
        setDocumentoKmz(nsir.documentoKmzFile);
      }
    }
  }, [nsir]);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const isDigitador = () => !isUsuarioUsoMuto(authData.email) && authData && authData.role === Roles.DIGITADOR && values.status === Status.EM_DIGITACAO && values.digitadorId === authData.id;
  const isInspetor = () => !isUsuarioUsoMuto(authData.email) && authData && authData.role === Roles.INSPETOR;
  const isAdmin = () => !isUsuarioUsoMuto(authData.email) && authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);
  const usuarioCroquiPodeEditar = () => !isUsuarioUsoMuto(authData.email) && authData && authData.role === Roles.CROQUI && (values.status === Status.EM_CRIACAO || values.status === Status.AGUARDANDO_INSPECAO);

  const getActions = () => {
    const errors = validate(nsir, getSchema());
    const isValid = errors === undefined || (errors && Object.keys(errors).length === 0 && errors.constructor === Object);

    const actions = [
      {
        onClick: () => setInspetorModal({ open: true, isChange: false }),
        title: 'Publicar para Inspeção',
        show: values.id !== undefined && values.status === Status.EM_CRIACAO && isAdmin() && isValid
      },
      {
        onClick: () => updateStatus({
          id: values.id,
          status: Status.EM_INSPECAO,
          inspetorId: values.inspetorId,
        }),
        title: 'Iniciar Inspeção',
        show:
          values.status === Status.AGUARDANDO_INSPECAO &&
          values.inspetorId === authData.id
      },
      {
        onClick: () => setInspetorModal({ open: true, isChange: true }),
        title: 'Trocar Inspetor',
        show: (values.status === Status.AGUARDANDO_INSPECAO || values.status === Status.INSPECAO_ENVIADA_PARCIAL) && isAdmin()
      },
      {
        onClick: () => updateStatus({
          id: values.id,
          status: Status.INSPECAO_FINALIZADA,
        }),
        title: 'Finalizar Inspeção com Error',
        show: [Status.INSPECAO_EM_ENVIO, Status.INSPECAO_SYNC_ERROR, Status.ERRO_AO_ENVIAR_INSPECAO].includes(values.status) && isAdmin()
      },
      {
        onClick: () => updateStatus({
          id: values.id,
          status: Status.INSPECAO_FINALIZADA,
          inspetorId: values.inspetorId,
        }),
        title: 'Finalizar Inspeção',
        show: (values.status === Status.EM_INSPECAO || values.status === Status.INSPECAO_ENVIADA_PARCIAL) && (values.inspetorId === authData.id || isAdmin())
      },
      {
        onClick: () => updateStatus({
          id: values.id,
          status: Status.AGUARDANDO_INSPECAO,
          inspetorId: values.inspetorId,
        }),
        title: 'Reabrir Inspeção',
        show: isAdmin() && (values.status === Status.INSPECAO_FINALIZADA || values.status === Status.AGUARDANDO_DIGITACAO
          || values.status === Status.EM_DIGITACAO)
      },
      {
        onClick: () => setDigitadorModal({ open: true, isChange: false }),
        title: 'Publicar para Digitação',
        show: values.status === Status.INSPECAO_FINALIZADA && isAdmin() && isValid
      },
      {
        onClick: () => iniciarDigitacaoNsir({
          id: values.id,
          digitadorId: authData.id
        }),
        title: 'Iniciar Digitação',
        show: Status.canDigitar(values.status) && (values.digitadorId === authData.id || values.digitadorId === null)
      },
      {
        onClick: () => setDigitadorModal({ open: true, isChange: true }),
        title: 'Trocar Digitador',
        show: values.status === Status.AGUARDANDO_DIGITACAO && isAdmin()
      },
      {
        onClick: () => trocarDigitadorNsir({ id: values.id, digitadorId: null }),
        title: 'Remover Digitador',
        show: (values.status === Status.AGUARDANDO_DIGITACAO || values.status === Status.EM_DIGITACAO) && values.digitadorId && isAdmin()
      },
      {
        onClick: () => updateStatus({
          id: values.id,
          status: Status.DIGITACAO_FINALIZADA,
          inspetorId: values.inspetorId,
        }, (open, message) => setDigitacaoFinalizadaError({ open, message })),
        title: 'Finalizar Digitação',
        show: values.status === Status.EM_DIGITACAO && (values.digitadorId === authData.id || isAdmin())
      },
      {
        onClick: () => updateStatus({
          id: values.id,
          status: Status.AGUARDANDO_DIGITACAO,
          inspetorId: values.inspetorId,
        }),
        title: 'Reabrir Digitação',
        show: values.status === Status.DIGITACAO_FINALIZADA && isAdmin()
      },
      {
        onClick: () => generateNsirReportById(values),
        title: 'Gerar Relatório',
        show: values.digitadorId === authData.id || isAdmin()
      },
      {
        onClick: () => updateStatus({
          id: values.id,
          status: Status.EXECUTADA_GDIS,
          inspetorId: values.inspetorId,
        }),
        title: 'Concluir NSIR',
        show: values.status === Status.DIGITACAO_FINALIZADA && isAdmin()
      },
      {
        onClick: () => setOpenCcsm(true),
        title: 'CCSM',
        show: (values.status === Status.DIGITACAO_FINALIZADA || values.status === Status.EXECUTADA_GDIS) && isAdmin()
      },
      {
        onClick: () => setStatusModal(true),
        title: 'Reabrir NSIR',
        show: values.status === Status.EXECUTADA_GDIS && isAdmin()
      },
      {
        onClick: () => onSaveLocal(),
        title: 'Salvar',
        show: isAdmin() || (values.status === Status.EM_INSPECAO && values.inspetorId === authData.id) || usuarioCroquiPodeEditar()
      },
    ];

    return actions;
  };

  const getSchema = () => {
    return {
      area: { presence: { allowEmpty: false, message: 'é obrigatório' } },
      equipamento: { presence: { allowEmpty: false, message: 'é obrigatório' } },
      malhaId: { presence: { allowEmpty: false, message: 'é obrigatório' } },
      prioridade: { presence: { allowEmpty: false, message: 'é obrigatório' } },
      anomalia: { presence: { allowEmpty: false, message: 'é obrigatório' } },
      inspecaoTermografica: { presence: { allowEmpty: false, message: 'é obrigatório' } },
    }
  };

  const onSaveLocal = () => {
    const errors = validateRequiredFields(values);
    if (
      errors === undefined ||
      (errors &&
        Object.keys(errors).length === 0 &&
        errors.constructor === Object)
    ) {
      onSave(values);
    }
  };

  const validateCoordenadas = data => {
    let errors = {};

    if (data.latitude && (data.latitude.trim().length !== 9 || !data.latitude.match(/\d{2}(,\d{6})?/))) {
      errors = { ...errors, latitude: ['O Campo é inválido'] };
    }

    if (data.longitude && (data.longitude.trim().length !== 9 || !data.longitude.match(/\d{2}(,\d{6})?/))) {
      errors = { ...errors, longitude: ['O Campo é inválido'] };
    }

    return errors;
  }

  const validateRequiredFields = data => {
    const currentErrors = { ...validate(data, getSchema()), ...validateCoordenadas(data) };
    setErrors(currentErrors);
    return currentErrors;
  };

  return (
    <>
      <Widget title="Informações Básicas" actions={getActions()} insideTab>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <OptionSelect
              label="Tipo de Serviço"
              codigo="TIPO_SERVICO"
              onSelect={value => setValues({
                ...values,
                tipoServicoId: value
              })}
              value={values.tipoServicoId}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <OptionSelect
              label="Cliente"
              codigo="CLIENTE"
              onSelect={value => setValues({
                ...values,
                clienteId: value
              })}
              value={values.clienteId}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <Input
              label="NSIR"
              name="codigo"
              onChange={handleChange}
              value={values.codigo}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <Input
              error={errors && errors.equipamento ? true : false}
              isRequired
              label="Equipamento"
              name="equipamento"
              onChange={handleChange}
              value={values.equipamento}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <DatePicker
              label="Data Vencimento"
              onChange={data =>
                setValues({
                  ...values,
                  vencimento: data.valueOf()
                })
              }
              value={values.vencimento}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <SimNaoSelect
              error={errors && errors.prioridade ? true : false}
              label="Prioridade"
              name="prioridade"
              onChange={handleChange}
              value={values.prioridade}
              disabled={!isAdmin()}
              isRequired
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <NsirStatusSelect value={values.status} disabled />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <NsirSyncStatusSelect value={values.syncStatus} disabled />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <TrueFalseSelect
              error={errors && errors.inspecaoTermografica ? true : false}
              label="Insp. Termográfica"
              name="inspecaoTermografica"
              onChange={handleChange}
              value={values.inspecaoTermografica}
              disabled={!isAdmin()}
              isRequired
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <SimNaoSelect
              error={errors && errors.anomalia ? true : false}
              label="Anomalia"
              name="anomalia"
              onChange={handleChange}
              value={values.anomalia}
              disabled={!isAdmin()}
              isRequired
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <DefaultSelect
              error={errors && errors.area ? true : false}
              isRequired
              label="Área"
              name="area"
              showEmpty
              options={[
                { id: 'RDR', nome: 'Rural' },
                { id: 'RDU', nome: 'Urbana' }
              ]}
              onSelect={handleChange}
              value={values.area}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <Input
              label="Data Inspeção"
              value={values.dataFinalInspecaoValue}
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <Input
              label="Data Digitação"
              value={values.dataFinalDigitacaoValue}
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <Input
              label="Data Executada GDIS"
              value={values.dataConclusaoValue}
              disabled
            />
          </Grid>
          <Grid item xs={3} sm={2} className={classes.fieldContainer}>
            <Input
              label="CT Plan"
              name="ctPlan"
              onChange={handleChange}
              value={values.ctPlan}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={3} sm={2} className={classes.fieldContainer}>
            <Input
              label="CT Exec"
              name="ctExec"
              onChange={handleChange}
              value={values.ctExec}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <UsuarioSelect
              label="Inspetor"
              onSelect={inspetorId => setValues({
                ...values,
                inspetorId
              })}
              ativo
              grupos={[Roles.ADMIN, Roles.INSPETOR]}
              value={values.inspetorId}
              disabled={values.status !== Status.EM_CRIACAO || (!isAdmin() && !usuarioCroquiPodeEditar())}
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <UsuarioSelect
              label="Observador/Ajudante"
              value={values.inspetorAuxiliarId}
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <UsuarioSelect
              label="Digitador"
              onSelect={digitadorId => setValues({
                ...values,
                digitadorId
              })}
              ativo
              grupos={[Roles.ADMIN, Roles.DIGITADOR]}
              value={values.digitadorId}
              disabled={[Status.AGUARDANDO_DIGITACAO, Status.EM_DIGITACAO, Status.DIGITACAO_FINALIZADA, Status.EXECUTADA_GDIS].includes(values.status) || !isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <Input label="Criado Por" disabled value={values.criadoPor} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <MalhaSelect
              error={errors && errors.malhaId ? true : false}
              isRequired
              onSelect={value =>
                setValues({
                  ...values,
                  malhaId: value,
                  gerenciaId: null,
                  poloId: null,
                  subestacaoId: null,
                  alimentadorId: null
                })
              }
              value={values.malhaId}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <GerenciaSelect
              onSelect={value =>
                setValues({
                  ...values,
                  gerenciaId: value,
                  poloId: null,
                  subestacaoId: null,
                  alimentadorId: null
                })
              }
              value={values.gerenciaId}
              malhaId={values.malhaId}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <PoloSelect
              onSelect={value =>
                setValues({
                  ...values,
                  poloId: value,
                  subestacaoId: null,
                  alimentadorId: null
                })
              }
              value={values.poloId}
              malhaId={values.malhaId}
              gerenciaId={values.gerenciaId}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <SubestacaoSelect
              onSelect={value =>
                setValues({
                  ...values,
                  subestacaoId: value,
                  alimentadorId: null
                })
              }
              value={values.subestacaoId}
              malhaId={values.malhaId}
              gerenciaId={values.gerenciaId}
              poloId={values.poloId}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <AlimentadorSelect
              onSelect={value =>
                setValues({
                  ...values,
                  alimentadorId: value
                })
              }
              value={values.alimentadorId}
              malhaId={values.malhaId}
              gerenciaId={values.gerenciaId}
              poloId={values.poloId}
              subestacaoId={values.subestacaoId}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <InputFormat
              error={errors && errors.latitude ? true : false}
              helperText={errors && errors.latitude ? 'O campo é inválido' : ''}
              name="latitude"
              label="Latitude"
              value={values.latitude}
              onChange={handleChange}
              disabled={!isAdmin()}
              format="##,######"
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <InputFormat
              error={errors && errors.longitude ? true : false}
              helperText={errors && errors.longitude ? 'O campo é inválido' : ''}
              name="longitude"
              label="Longitude"
              value={values.longitude}
              onChange={handleChange}
              disabled={!isAdmin()}
              format="##,######"
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <Input
              label="Km"
              name="km"
              onChange={handleChange}
              value={values.km}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <Input
              label="Km Urbano"
              name="kmRdu"
              onChange={handleChange}
              value={values.kmRdu}
              disabled={!isAdmin() && !usuarioCroquiPodeEditar()}
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <Input
              label="Km Rural"
              name="kmRdr"
              onChange={handleChange}
              value={values.kmRdr}
              disabled={!isAdmin() && !usuarioCroquiPodeEditar()}
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <Input
              label="Km Inspecionado"
              name="kmInspecao"
              onChange={handleChange}
              value={values.kmInspecao}
              disabled={!isAdmin() && !usuarioCroquiPodeEditar()}
            />
          </Grid>
          <Grid item xs={6} sm={2} className={classes.fieldContainer}>
            <Input
              label="Situação"
              name="situacao"
              onChange={handleChange}
              value={values.situacao}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={6} sm={3} className={classes.fieldContainer}>
            <Input
              label="Cidade"
              name="cidade"
              onChange={handleChange}
              value={values.cidade}
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={12} sm={7} className={classes.fieldContainer}>
            <Input
              label="Croqui"
              name="documentoLink"
              onChange={handleChange}
              value={values.documentoLink}
              disabled={!isAdmin() && !usuarioCroquiPodeEditar()}
            />
          </Grid>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Input
              label="Observação da NSIR"
              name="observacao"
              onChange={handleChange}
              value={values.observacao}
              rows={4}
              multiline
              disabled={!isAdmin()}
            />
          </Grid>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Input
              label="Observação do Inspetor"
              name="observacaoInspetor"
              onChange={handleChange}
              value={values.observacaoInspetor}
              rows={4}
              multiline
              disabled={!isInspetor()}
            />
          </Grid>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Input
              label="Observação do Digitador"
              name="observacaoDigitador"
              onChange={handleChange}
              value={values.observacaoDigitador}
              rows={4}
              multiline
              disabled={!isDigitador()}
            />
          </Grid>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Dropzone
              buttonText="Adicionar Arquivo KMZ"
              filesLimit={1}
              acceptedFiles={['.kmz', '.pdf', '.xls', '.xlsb']}
              onSave={files => {
                if (files != null && files.length > 0) {
                  setDocumentoKmz(files[0]);
                  values.documentoKmzFile = files[0];
                  values.existDocumentoKmz = true;
                  values.documentoKmz = files[0].path;
                }
              }}
              disabled={!isAdmin() && !usuarioCroquiPodeEditar()}
              showGridFiles
              files={documentoKmz != null ? [{
                file: documentoKmz,
                nome: documentoKmz.name,
                onDelete: () => {
                  setDocumentoKmz(null);
                  values.documentoKmzFile = null;
                  values.existDocumentoKmz = false;
                  values.documentoKmz = null;
                  values.documentoKmzLink = null;
                }
              }] : []}
              canDeleteFiles={isAdmin() || usuarioCroquiPodeEditar()}
            />
          </Grid>
        </Grid>
      </Widget>
      <UsuarioModal
        grupos={[Roles.ADMIN, Roles.INSPETOR]}
        isDigitador={false}
        usuarioId={values.inspetorId}
        message={inspetorModal.isChange ? 'Alinhar com o inspetor antes de realizar a troca' : null}
        open={inspetorModal.open}
        handlerCancel={() => setInspetorModal({ open: false, isChange: false })}
        handlerConfirm={usuario => {
          setInspetorModal({ open: false, isChange: false });
          updateStatus({
            id: values.id,
            status: values.status === Status.INSPECAO_ENVIADA_PARCIAL ? Status.INSPECAO_ENVIADA_PARCIAL : Status.AGUARDANDO_INSPECAO,
            inspetorId: usuario,
            digitadorId: values.digitadorId
          });
        }}
      />
      <UsuarioModal
        grupos={[Roles.ADMIN, Roles.DIGITADOR]}
        isDigitador
        usuarioId={values.digitadorId}
        message={digitadorModal.isChange ? 'Alinhar com o digitador antes de realizar a troca' : null}
        open={digitadorModal.open}
        handlerCancel={() => setDigitadorModal({ open: false, isChange: false })}
        handlerConfirm={usuario => {
          setDigitadorModal({ open: false, isChange: false });
          trocarDigitadorNsir({ id: values.id, digitadorId: usuario });
        }}
      />
      <StatusModal
        open={statusModal}
        handlerCancel={() => setStatusModal(false)}
        handlerConfirm={status => {
          setStatusModal(false);
          updateStatus({
            id: values.id,
            status: status,
            inspetorId: values.inspetorId,
            digitadorId: values.digitadorId
          });
        }}
      />
      <DialogDefault
        open={openCcsm}
        handlerCancel={() => setOpenCcsm(false)}
        handlerConfirm={() => {
          exportCcsm(values.id);
          setOpenCcsm(false);
        }}
        title="Confirmação"
        confirmMessage="Sim"
        cancelMessage="Não"
      >
        <DialogContentText> Deseja exportar o relatório CCSM? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={digitacaoFinalizadaError.open}
        readOnly
        handlerCancel={() => setDigitacaoFinalizadaError({ open: false, message: '' })}
        title="Erro"
      >
        <DialogContentText> {digitacaoFinalizadaError.message} </DialogContentText>
      </DialogDefault>
    </>
  );
};

InformacaoBasicaTab.propTypes = {
  onSave: PropTypes.func.isRequired,
  nsir: PropTypes.object.isRequired,
  authData: PropTypes.object.isRequired,
  generateNsirReportById: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  iniciarDigitacaoNsir: PropTypes.func.isRequired,
  trocarDigitadorNsir: PropTypes.func.isRequired,
  exportCcsm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionsNsirs
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InformacaoBasicaTab);
