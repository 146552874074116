/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as selectorsNsir from '../../store/nsir/reducer';
import { isUsoMuto } from 'utils';
import UsoMutuoModal from './nsmp-view/UsoMutuoModal';
import DefaultModal from './nsmp-view/DefaultModal';

const NsmpModal = ({ open, onCancel, onConfirm, selectedId, readOnly, nsirData, nsirTitle }) => {
  if (isUsoMuto(nsirData.tipoServicoId)) {
    return <UsoMutuoModal 
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      selectedId={selectedId}
      readOnly={readOnly}
      nsirTitle={nsirTitle}
      digitadorId={nsirData.digitadorId}
    />
  }
  
  return <DefaultModal 
    open={open}
    onCancel={onCancel}
    onConfirm={onConfirm}
    selectedId={selectedId}
    readOnly={readOnly}
    nsirTitle={nsirTitle}
  />
};

NsmpModal.propTypes = {
  onCancel: PropTypes.func.isRequired, 
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedId: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  nsirData: PropTypes.object.isRequired,
};

NsmpModal.defaultProps = {
  selectedId: undefined,
};

const mapStateToProps = state => ({
  nsirData: selectorsNsir.getNsirSelected(state),
});

export default connect(mapStateToProps, null)(NsmpModal);
