/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid, DialogContentText, Typography } from '@material-ui/core';
import { Table, DefaultFiltro, DialogDefault } from '../../components';
import * as selectorsUsuarios from '../../store/usuario/reducer';
import * as actionsUsuarios from '../../store/usuario/actions';
import Formulario from './Formulario';
import * as Roles from '../../constants/role';
import * as selectorsAuth from '../../store/auth/reducer';
import { masks } from '../../helpers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const Usuarios = ({ usuarios, loadUsuarios, deleteUsuario, exportUsuarios, authData }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [titles] = useState(['Nome', 'Usuário', 'Perfil', 'Setor', 'Ativo']);
  const [localFilter, setLocalFilter] = useState({});
  const [openExport, setOpenExport] = useState(false);

  const classes = useStyles();
  
  const renderRows = s => {
    return (
      <>
        <TableCell>
          <div alignItems="center" direction="row" spacing={1}>
            <div>
                <Typography>{s.nome} - {s.matricula}</Typography>
                <Typography color="text.secondary" style={{color: "#546e7a"}}>{masks.phoneMask(s.telefone)}</Typography>
            </div>
          </div>
        </TableCell>
        <TableCell>{s.email}</TableCell>
        <TableCell>
          <div alignItems="center" direction="row" spacing={1}>
            <div>
                <Typography>{s.roleName}</Typography>
                {s.subRoleName && <Typography color="text.secondary" variant="body2">{s.subRoleName}</Typography>}
            </div>
          </div>
        </TableCell>
        <TableCell>{s.setorName}</TableCell>
        <TableCell>{s.ativo ? 'Sim' : 'Não'}</TableCell>
      </>
    )
  }

  useEffect(() => {
    if (isAdmin()) {
      loadUsuarios(localFilter);
    } else {
      loadUsuarios({ ...localFilter, usuarioAtivo : true });  
    }

  }, [loadUsuarios, localFilter, authData]);


  const onEdit = id => {
    setOpen(true);
    setId(id);
  }

  const onDelete = id => deleteUsuario(id);
  const onAdd = () => {
    setOpen(true);
    setId(null);
  }

  const onClose = (isSave) => {
    if (isSave) {
      loadUsuarios(localFilter);
    }
    setOpen(false);
    setId(null);
  }

  const getHeaderActions = () => {
    let actions = [];

    if (process.env.REACT_APP_USUARIO_EXPORT_REPORT.split(',').includes(authData.email)) {
      actions.push({ 
        title: 'Exportar',
        onClick: () => setOpenExport(true)
      });
    }

    return actions;
  }

  const isAdmin = () => authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);

  const canAdd = () => process.env.REACT_APP_USUARIO_CAN_ADD.split(',').includes(authData.email)

  return (
    <Grid container>
      <Grid item xs={12}>
        <DefaultFiltro
          useRole
          useSetor
          usePossuiCarteira
          onFilter={filterData => {
            setLocalFilter(filterData);
            loadUsuarios(filterData);
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={usuarios}
          titles={titles}
          headerTitle="Usuários"
          deleteMessage="O usuário será removido e não poderá ser mais recuperado."
          renderRows={renderRows}
          hasRowActions
          onEdit={onEdit}
          onDelete={onDelete}
          onAdd={onAdd}
          showDelete={false}
          showEdit={isAdmin()}
          showView={!isAdmin()}
          onView={onEdit}
          hasAdd={canAdd()}
          headerActions={getHeaderActions()}
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
          readOnly={!isAdmin()}
          isAdmin={isAdmin()}
          userEmail={authData.email}
        />
        <DialogDefault
          open={openExport}
          useFullScreen={false}
          handlerCancel={() => setOpenExport(false)}
          handlerConfirm={() => {
            exportUsuarios(localFilter, () => {
              setOpenExport(false);
            });
          }}
          title="Confirmação"
          confirmMessage="Sim"
          cancelMessage="Não"
        >
          <DialogContentText> Deseja baixar todas as informações filtradas? </DialogContentText>
        </DialogDefault>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  usuarios: selectorsUsuarios.getUsuarios(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsUsuarios
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Usuarios));